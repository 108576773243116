<template>
  <div class="wrapper">
    <div class="display-f align-items-c justify-end">
      <span>消息类型</span>
      <div class="margin-l20">
        <el-select v-model="searchForm.messageType" slot="prepend" placeholder="请选择消息类型" @change="getList">
          <el-option label="日常" value="0"></el-option>
          <el-option label="故障报警" value="1"></el-option>
        </el-select>
      </div>
    </div>
    <div class="margin-t30">
      <div class="notice-item" v-for="(item, index) in list" :key="item.id" @click="setRead(item)">
        <div class="left">
          <img src="@/assets/image/notice/notice_warn.png" alt="" v-if="item.messageType === '1'">
          <img src="@/assets/image/notice/notice.png" alt="" v-else>
        </div>
        <div class="right">
          <div class="flex1" style="width: 0">
            <div class="font-s22 font-wb">{{ item.messageTitle }}</div>
            <div class="margin-t10 omit2">{{ item.messageContent }}</div>
          </div>
          <div>
            <div>{{ item.createTime }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNoticeList, setNoticeRead } from '../../api/notice/notice'
import { mapState } from 'vuex'

export default {
  name: 'messageCenter',
  data () {
    return {
      searchForm: {},
      page: 1,
      list: [],
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList() {
      getNoticeList({
        messageType: this.searchForm.messageType
      }).then(res => {
        this.list = res.data
      })
    },
    setRead (item) {
      setNoticeRead({
        noticeId: item.id
      }).then(() => {
        item.readFlag = true
      })
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped lang="scss">
  .notice-item {
    background: #232733;
    border: 1px solid #3F5370;
    border-radius: 10px;
    height: 110px;
    display: flex;
    margin-bottom: 10px;
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px dashed #3F5370;
      width: 130px;
    }
    .right {
      flex: 1;
      display:  flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px
    }
  }
</style>
